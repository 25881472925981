<template>
  <div class="myNewsDetails MYNewsDetails">
    <!-- 消息详情 -->
    <van-nav-bar title="消息详情"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <div class="title">
        <div class="name">{{msg.wxtitle}}</div>
        <div class="desc">
          <span>{{msg.wxname}}</span>
          <span>发布时间：{{msg.crdate}}</span>
        </div>
      </div>
      <div class="newHtml"
           v-html="msg.wxmsg"></div>
      <div class="footer">
        <van-button v-if="msg.url"
                    type="info"
                    @click="goto">查看详情</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAlarmMsgDetail } from '@api/wx.js'
export default {
  data () {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      autoid: this.$route.query.autoid,
      from: this.$route.query.from,
      msg: {},
      V3url: './'
    }
  },
  created () {
    this.getMsgDetails()
  },
  methods: {
    onClickLeft () {
      let fr = this.from || this.$route.query.from
      if (fr == 'home')
      {
        this.$router.push('/home')
      } else
      {
        this.$router.push('/msgCenter')
      }
    },
    getMsgDetails () {
      getAlarmMsgDetail({
        autoid: this.autoid
      }).then(res => {
        this.msg = res.data[0]
        this.msg.wxmsg = this.msg.wxmsg ? this.msg.wxmsg.replace(/\\n/g, "\n") : ''
        this.msg.wxmsg = this.msg.wxmsg ? this.msg.wxmsg.replace(/\r/g, "\n") : ''
        // 2022/10/15 伊之密要求如果有地址直接跳转
        if (this.msg.url) this.goto()
      })
    },
    goto () {
      let url = ''
      let u = this.msg.url
      if (u.indexOf('http') > -1)
      {
        url = u
      } else
      {
        url = this.V3url + u
      }
      if (url.indexOf('yidongqiandao') > -1)
      {
        window.location.href = url
      } else
      {
        this.$router.push({
          path: '/jump',
          query: { url: url, time: new Date().getTime() }
        })
      }
      this.reload()
    }
  }
}
</script>

<style lang="less" scoped>
.myNewsDetails {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    .title {
      padding: 20px;
      .name {
        padding: 30px 0;
        font-size: 36px;
        font-weight: 500;
        text-align: center;
      }
      .desc {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        color: #999999;
      }
    }
    .newHtml {
      padding: 30px;
      white-space: pre-wrap;
      p {
        line-height: 1.5 !important;
      }
    }
    .footer {
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      bottom: 125px;
      left: 50%;
      transform: translateX(-50%);
      .van-button {
        margin-bottom: 15px;
        width: 90%;
      }
    }
  }
}
</style>